import { createStore } from 'vuex'
import app from './modules/app'
import point from './modules/point'
import findOrderState from './modules/findOrder'
// pointGroupSalesInfo: {}

export default createStore({
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    point,
    findOrder: findOrderState
  }
})
