export default {
    'newShelves.spmc': 'Product Description',
    'newShelves.xzsjkcs': 'Count',
    'newShelves.hdh': 'Position',
    'newShelves.hdmtip': '由于货道已满，以下商品未上架',
    'newShelves.hd': 'Position',
    'newShelves.yxh': '已选好',
    'newShelves.cxxz': '重新选择',
    'newShelves.mykxcp': '没有可选菜品',
    'newShelves.qrsj': 'Confirm replenishment',
    'newShelves.sjtp': 'Upload picture of completed replenished job. (Max 9 pictures allowed)',
    'newShelves.hdym': '货道已满',
    'newShelves.tjsl': '请添加库存上架数量',
    'newShelves.ddscjs': '请等待照片上传结束',
    'newShelves.zsyzzp': '请至少上传一张图片',
    'newShelves.sbmc': '设备名称',
    'newShelves.sjsj': '上架时间',
    'newShelves.gzmc': '柜子名称',
    'newShelves.sjs': '上架数'
}