export default {
    'onSales.hd': '货道',
    'onSales.spmc': '名称',
    'onSales.ks': '可售',
    'onSales.sdkc': '锁定',
    'onSales.cz': '操作',
    'onSales.plxh': '批量销货',
    'onSales.qrxh': '确认销货',
    'onSales.xhsp': '销货商品',
    'onSales.kssl': '可售数量',
    'onSales.xhs': '销货数',
    'onSales.xhyy': '销货原因',
    'onSales.xzyytip': '点击选择原因',
    'onSales.bzsm': '备注说明',
    'onSales.qsrbz': '请输入备注说明',
    'onSales.tp': '图片',
    'onSales.tptip': '备注：上传销货商品照片，过期商品需要清晰的过期时间信息，最少一张照片，最多上传9张照片',
    'onSales.tj': '提交',
    'onSales.spgq': '商品过期',
    'onSales.spps': '商品破损',
    'onSales.ygc': '员工餐',
    'onSales.sjyc': '上架异常',
    'onSales.cpzlwt': '菜品质量问题',
    'onSales.cs': '测试',
    'onSales.qt': '其他',
    'onSales.pictip': '请等待照片上传结束',
    'onSales.xhyyxz': '请销货选择原因',
    'onSales.sczptip': '请至少上传一张图片',
    'onSales.sbmc': '设备名称',
    'onSales.xhsj': '销货时间',
    'onSales.sl': '数量',
    'onSales.xhsb': '销货设备',
    'onSales.nr': '内容',
    'onSales.xhgz': '销货柜子',
    'onSales.bz': '备注',
    'onSales.xzxhyy': '选择销货原因',
    'onSales.xzxhyytip': '注：涉及成本计算，请按照实际情况准确选择销货原因',
    'onSales.qd': '确定',
    // 1:上错货 2:调整商品货道 3:盘点差异 4调拨出入库 5出库后回库 6:过期 7:商品破损 8:新品下架 9:其他
    'onSales.gq': '过期',
    'onSales.xpxj': '新品下架',
    'onSales.qxxyy': '请选择原因',
    'onSales.search.tip': '请输入商品名称',
    'onSales.qxxxhsl': '请选择销货数量'
    // 'onSales.spppictips: '请等待'
}