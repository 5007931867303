export default {
    'stock.zkc': '总库存',
    'stock.hd': '货道',
    'stock.sp': '商品',
    'stock.kc': '库存',
    'stock.hdh': '货道号',
    'stock.spmc': '商品名称',
    'stock.sjtip': '超量数据可以后面补货上架到货柜',
    'stock.lx': '类型',
    'stock.sl': '数量'
}