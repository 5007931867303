export default {
    'giftCard.zh': '手机号',
    'giftCard.sryzm': '请输入验证码',
    'giftCard.tit': '输入手机号立即领取',
    'giftCard.mflq': '免费领取',
    'giftCard.zhtip': '请输入手机号',
    'giftCard.fsyam': '发送验证码',
    'giftCard.sjtip': '活动时间：',
    'giftCard.jsq': '活动解释权归',
    'giftCard.ckwdlpk': '查看我的礼品卡',
    'giftCard.zg': '中国',
    'giftCard.xjp': '新加坡',
    'giftCard.mg': '美国',
    'giftCard.qxyzsjh': '请先验证手机号',
    'giftCard.sjcwts': '请输入正确的手机号码',
    'giftCard.yamcwts': '请输入正确的验证码',
    'giftCard.wdlpk': '我的礼品卡',
    'giftCard.ye': '余额',
    'giftCard.sysm': '使用说明',
    'giftCard.lqcg': '领取成功！',
    'giftCard.mz': '面值',
    'giftCard.mztip': '的礼品卡已经放入您的账户',
    'giftCard.y': '元',
    'giftCard.yxq': '有效期',
    'giftCard.dz': '地址',
    'giftCard.dhm': '兑换码',
    'giftCard.verytip': ''
}