export default {
  setup() {
    const themeVars = {
      // rateIconFullColor: '#07c160',
      // sliderBarHeight: '4px',
      // sliderButtonWidth: '20px',
      // sliderButtonHeight: '20px',
      // sliderActiveBackgroundColor: '#07c160',
      buttonPrimaryBorderColor: '#CE1528',
      buttonPrimaryBackgroundColor: '#CE1528'
    };
    return {
      themeVars
    };
  }
};