export default {
    'pointDetail.yy': '营业',
    'pointDetail.ty': '停业',
    'pointDetail.kctp': '库存调平',
    'pointDetail.xh': '销货',
    'pointDetail.sj': '上架',
    'pointDetail.rw': '任务',
    'pointDetail.dbd': '打包袋',
    'pointDetail.hdlb': '货道列表',
    'pointDetail.splb': '商品列表',
    'pointDetail.hd': '货道',
    'pointDetail.wbdcp': '未绑定菜品',
    'pointDetail.kc': '库存',
    'pointDetail.zkc': '总库存',
    'pointDetail.lgkc': '冷柜库存',
    'pointDetail.cjtlkc': '餐具调料柜库存',
    'pointDetail.lhgn': '理货功能',
    'pointDetail.ckkc': '查看库存',
    'pointDetail.lgsjkc': '冷柜上架库存',
    'pointDetail.cjsjkc': '餐具调料柜上架库存',
    'pointDetail.lg': '冷柜',
    'pointDetail.cjtl': '餐具调料柜',
    'pointDetail.c&b': '查看&补货',
    'pointDetail.hy': '还有',
    'pointDetail.d': '等',
    'pointDetail.dtip': '个商品超量未完成上架'
}