export default {
    'giftCardSg.dhm': 'Redemption code',
    'giftCardSg.yxq': 'Oeriod of validity',
    'giftCardSg.xztip': 'Download Instachef to view gift cards',
    'giftCardSg.xztip1': 'Download Instachef and use it now',
    'giftCardSg.xz': 'Download Now',
    'giftCardSg.phonetip': 'Enter your phone number to receive a free',
    'giftCardSg.lpk': 'gift card.',
    'giftCardSg.lqcgtext': 'Received successfully',
    'giftCardSg.to': 'to',
    'giftCardSg.mflq': 'Free Collection',
    'giftCardSg.sjtip': 'Voucher Redemption Date:',
    'giftCardSg.verytip': 'By signing up for phone number and text, you agree to Instachef’s Terms of Service and Privacy Policy. By signing up, you agree to receive automated promotional and personalized marketing messages. Consent is not a condition of any purchase. Reply STOP to cancel. Msg frequency varies and data rates may apply. View Terms and Privacy.Aikit Pte Ltd.all rights reserved.',
    'giftCardSg.emailtip': 'Enter your email to receive a free',
    'giftCard.yxtip': 'Enter your email',
    'giftCard.yxfscg': 'The verification code has been sent to the email.',
    'giftCardSg.yxcwts': 'The email is incorrect. Please check and try again.'
}