export default {
    'me.xhjl': 'Returns Report',
    'me.tpjl': 'Inventory Adjustment Report',
    'me.sjjl': 'Replenishment Report',
    'me.xgmm': 'Change Password',
    'me.xgyy': 'update language',
    'me.tcdl': 'Sign Out',
    'me.lhy': 'Driver',
    'me.qrtc': 'Are you sure to exit?',
    'me.home': 'Home',
    'me.me': 'Me',
    'me.mm': 'Password',
    'me.xmm': 'New Password',
    'me.xmmqr': 'Confirm New Password',
    'me.tj': 'Save',
    'me.bnwk': 'Cannot be empty',
    'me.byz': 'The passwords are inconsistent',
    'me.zh': 'Account',
    'me.srsjh': 'Please enter your mobile number',
    'me.srsmm': 'Please enter your login password',
    'me.dl': 'Sign in',
    'me.set': 'Set',
    'me.passwordtip': 'Password with 8-16 digits, must consist of uppercase letters, lowercase letters, and numbers',
    'me.passwordDatetip': 'Please change your password every 3 months',
    'me.xgcg': 'Successfully modified, please log in again'
}