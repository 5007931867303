// export default {
//     'pointDetail.yy': 'Open',
//     'pointDetail.ty': 'Closed',
//     'pointDetail.kctp': 'Inventory Adjustment',
//     'pointDetail.xh': 'Return',
//     'pointDetail.sj': 'Replenishment',
//     'pointDetail.rw': 'Others',
//     'pointDetail.dbd': '打包袋',
//     'pointDetail.hdlb': 'Inventory',
//     'pointDetail.splb': 'Product',
//     'pointDetail.hd': 'Position',
//     'pointDetail.wbdcp': '未绑定菜品',
//     'pointDetail.kc': 'Inventory on hand',
//     'pointDetail.zkc': '总库存',
//     'pointDetail.lg': '冷柜',
//     'pointDetail.cjtl': '餐具调料柜',
//     'pointDetail.c&b': '查看&补货',
//     'pointDetail.hy': '还有',
//     'pointDetail.d': '等',
//     'pointDetail.dtip': '个商品超量未完成上架'
// }
export default {
    'pointDetail.yy': 'Open',
    'pointDetail.ty': 'Closed',
    'pointDetail.kctp': 'Inventory Adjustment',
    'pointDetail.xh': 'Return',
    'pointDetail.sj': 'Replenishment',
    'pointDetail.rw': 'Others',
    'pointDetail.dbd': 'Bag',
    'pointDetail.hdlb': 'Inventory',
    'pointDetail.splb': 'Product',
    'pointDetail.hd': 'Position',
    'pointDetail.wbdcp': 'Unbound Product',
    'pointDetail.kc': 'Inventory',
    'pointDetail.zkc': 'Total Inventory',
    'pointDetail.lgkc': 'Refrigerator Inventory',
    'pointDetail.cjtlkc': 'Cupboard Inventory',
    'pointDetail.lhgn': 'Inventory Adjustment',
    'pointDetail.ckkc': 'View Inventory',
    'pointDetail.lgsjkc': 'Refrigerator Replenishment Inventory',
    'pointDetail.cjsjkc': 'Cupboard Replenishment Inventory',
    'pointDetail.lg': 'Refrigerator',
    'pointDetail.cjtl': 'Cupboard',
    'pointDetail.c&b': 'View&Replenishment',
    'pointDetail.hy': 'Still',
    'pointDetail.d': '',
    'pointDetail.dtip': 'products are not replenished'
}
