export default {
    'newShelves2.noroad': '没有上架商品，无需核对库存',
    'newShelves2.kc': '库存',
    'newShelves2.hy': '还有',
    'newShelves2.d': '等',
    'newShelves2.dtip': '个商品超量未完成上架',
    'newShelves2.ckxq': '查看详情',
    'newShelves2.xg': '修改',
    'newShelves2.qr': '确认',
    'newShelves2.hdh': '货道号',
    'newShelves2.spmc': '商品名称',
    'newShelves2.cltip': '超量数据可以后面补货上架到货柜',
    'newShelves2.lx': '类型',
    'newShelves2.sl': '数量',
    'newShelves2.bky': '不可用'
}