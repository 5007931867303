// import storage from 'store'
// // import moment from 'moment'
// import {
//   // i18n
//   APP_LANGUAGE
// } from '@/store/mutation-types'

import { createI18n } from 'vue-i18n';
import ZH from './lang/zh-CN.js';
import EN from './lang/en-US.js';

const navLang = navigator.language ? navigator.language : 'zh-CN'
// export const defaultLang = storage.get(APP_LANGUAGE) || 'zh-CN'

export const defaultLang = navLang === 'zh-CN' ? 'zh-CN' : 'en-US'
// export const defaultLang = navLang === 'zh-CN' ? 'en-US' : 'en-US'
const messages = {
  'zh-CN': { ...ZH  },
  'en-US': { ...EN  }
};

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: defaultLang,
  messages
});
export default i18n;
