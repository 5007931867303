export default {
    'me.xhjl': '销货记录',
    'me.tpjl': '调平记录',
    'me.sjjl': '上架记录',
    'me.xgmm': '修改密码',
    'me.xgyy': '修改语言',
    'me.tcdl': '退出登录',
    'me.lhy': '理货员',
    'me.qrtc': '确认退出？',
    'me.home': '首页',
    'me.me': '我的',
    'me.mm': '密码',
    'me.xmm': '新密码',
    'me.xmmqr': '新密码确认',
    'me.tj': '提交',
    'me.bnwk': '不能为空',
    'me.byz': '两次密码不一致',
    'me.zh': '账号',
    'me.srsjh': '请输入手机号码',
    'me.srsmm': '请输入登录密码',
    'me.dl': '登录',
    'me.set': '设置',
    'me.passwordtip': '密码8-16位，必须由大写字母，小写字母和数字组成',
    'me.passwordDatetip': '请3个月修改一次密码',
    'me.xgcg': '修改成功, 请重新登陆'
}