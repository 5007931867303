export default {
    'invite.fsyzm': 'Send OTP',
    'invite.btntext1': 'Get',
    'invite.btntext2': 'coupon',
    'invite.wty': 'I have read and agre',
    'invite.yhxy': 'User Agreement',
    'invite.yhxy2': 'Privacy Agreement',
    'invite.and': 'and',
    'invite.zh': 'Congratulations! You’ve got',
    'invite.yhq': 'coupon',
    'invite.xztip': 'Download InstaChef APP to enjoy special discounts and delicious food!',
    'invite.xz': 'Download Now',
    'invite.verytip': 'By signing up, you agree to receive promotional messages from InstaChef. Reply STOP to opt-out. Message frequency varies, and data rates may apply. View Terms and Privacy Policy. InstaChef and Aikit Pte Ltd. All rights reserved.',
    'invite.verytip1': 'By signing up, you agree to receive promotional messages from InstaChef. Reply STOP to opt-out. Message frequency varies, and data rates may apply. View',
    'invite.verytip2': 'Terms and Privacy Policy',
    'invite.verytip3': '. All rights reserved by InstaChef and Aikit Pte Ltd.',
    'invite.verytipus1': 'By signing up, you agree to receive promotional messages from InstaChef. Reply STOP to opt-out. Message frequency varies, and data rates may apply. View Terms and Privacy Policy. All rights reserved by InstaChef and Aikit Pte Ltd.',
    'invite.verytipus2': 'Terms and Privacy Policy',
    'invite.verytipus3': '. All rights reserved by InstaChef and Aikit Pte Ltd.',
}