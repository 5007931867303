// import storage from 'store'
// // import { loadLanguageAsync } from '@/locales'

const UPDATE_CART_LIST = 'UPDATE_CART_LIST'
const UPDATE_ORDER_LIST = 'UPDATE_ORDER_LIST'
const findOrderState = {
    state: {
        cartList: [],
        orderList: []
    },
    getters: {
      getCartList: state => state.cartList,
      getOrderList: state => state.orderList
    },
    mutations: {
        [UPDATE_CART_LIST]: (state, list) => {
            state.cartList = list
        },
        [UPDATE_ORDER_LIST]: (state, list) => {
            state.orderList = list
        }
    },
    actions: {
        setCartList ({ commit }, list) {
          return new Promise((resolve) => {
            commit(UPDATE_CART_LIST, list)
            resolve()
          })
        },
        setOrderList ({ commit }, list) {
            console.log('setOrderList', list)
          return new Promise((resolve) => {
            commit(UPDATE_ORDER_LIST, list)
            resolve()
          })
        }
    }
}
export default findOrderState