import pointList from './en-US/pointList'
import list from './en-US/list'
import pointDetail from './en-US/pointDetail'
import stockLevel from './en-US/stockLevel'
import search from './en-US/search'
import onSales from './en-US/onSales'
import bagDetail from './en-US/bagDetail'
import onShelves from './en-US/onShelves'
import me from './en-US/me'
import task from './en-US/task'
import enumerate from './en-US/enumerate'
import modal from './en-US/modal'
import onShelves2 from './en-US/onShelves2'
import replenish from './en-US/replenish'
import giftCard from './en-US/giftCard'
import title from './en-US/title'
import giftCardSg from './en-US/giftCardSg'
import invite from './en-US/invite'
export default {
    login: 'login',
    momentName: 'eu',
    ...pointList,
    ...list,
    ...pointDetail,
    ...stockLevel,
    ...search,
    ...onSales,
    ...bagDetail,
    ...onShelves,
    ...me,
    ...task,
    ...enumerate,
    ...modal,
    ...onShelves2,
    ...replenish,
    ...giftCard,
    ...title,
    ...giftCardSg,
    ...invite
}