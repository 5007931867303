export default {
    'onSales.hd': 'Position',
    'onSales.spmc': 'Product Description',
    'onSales.ks': 'Current inventory',
    'onSales.sdkc': 'Lock',
    'onSales.cz': 'operate',
    'onSales.plxh': 'Confirm Adjustment',
    'onSales.qrxh': 'Confirm sales',
    'onSales.xhsp': 'Product Description',
    'onSales.kssl': 'Current inventory',
    'onSales.xhs': 'Returns quantity',
    'onSales.xhyy': 'Cause for returns',
    'onSales.xzyytip': 'Click to select the reason',
    'onSales.bzsm': 'Remarks',
    'onSales.qsrbz': 'Please enter comments',
    'onSales.tp': 'Picture',
    'onSales.tptip': "Upload picture of the returns. If it's due to expiry, take a picture of the expiry date with the product. (Upload at least 1 picture and not more than 9 pictures)",
    'onSales.tj': 'submit',
    'onSales.spgq': 'Reason for expired products',
    'onSales.spps': 'Damaged goods',
    'onSales.ygc': 'Staff meals',
    'onSales.sjyc': 'Reason for adnormal expiry date for new product.',
    'onSales.cpzlwt': 'Reason for potential quality issue.',
    'onSales.cs': 'Samples',
    // 'onSales.qt': 'Others',
    'onSales.pictip': 'Please wait for the photo upload to finish',
    'onSales.xhyyxz': 'Please select the reason for sales',
    'onSales.sczptip': 'Please upload at least one image',
    'onSales.sbmc': 'Equipment name',
    'onSales.xhsj': 'Sales time',
    'onSales.sl': 'quantity',
    'onSales.xhsb': 'Sales equipment',
    'onSales.nr': 'content',
    'onSales.xhgz': 'Sales cabinet',
    'onSales.bz': 'Remarks',
    'onSales.xzxhyy': 'Select sales reason',
    'onSales.xzxhyytip': 'Note: If cost calculation is involved, please accurately select the leveling reason according to the actual situation',
    'onSales.qd': 'determine',
    // 1:上错货 2:调整商品货道 3:盘点差异 4调拨出入库 5出库后回库 6:过期 7:商品破损 8:新品下架 9:其他
    'onSales.gq': 'Product Expiration',
    'onSales.xpxj': 'New product off the shelf',
    'onSales.qt': 'other',
    'onSales.qxxyy': 'Please select sales reason',
    'onSales.qxxxhsl': 'Please select the sales quantity'
}
