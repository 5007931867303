import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/index'
import { ConfigProvider } from 'vant';
import 'vant/es/dialog/style';
import 'animate.css';
import '@/assets/style/reset.css'
import './index.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(i18n)
app.use(ConfigProvider)
app.mount('#app')
