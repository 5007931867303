import { createRouter, createWebHashHistory } from 'vue-router'
import i18n from '@/locales/index.js'
const routes = [
  {
    path: '/',
    redirect: ''
  },
  {
    path: '/activety',
    redirect: '/activety/giftCard',
    name: 'activety',
    meta: {title: i18n.global.t('title.activety')},
    children: [
      {
        path: 'giftCard',
        name: 'giftCard',
        meta: {title: i18n.global.t('title.giftCard')},
        component: () => import(/* webpackChunkName: "redeemGiftCard" */ '@/views/activity/giftCard/redeemGiftCard.vue'),
      },
      {
        path: 'giftCardResultUs',
        name: 'giftCardResultUs',
        meta: {title: i18n.global.t('title.giftCardResult')},
        component: () => import(/* webpackChunkName: "giftCardResult" */ '@/views/activity/giftCard/result.vue'),
      },
      {
        path: 'giftHistorUs',
        name: 'giftHistoryUs',
        meta: {title: i18n.global.t('title.giftHistory')},
        component: () => import(/* webpackChunkName: "giftHistory" */ '@/views/activity/giftCard/history.vue'),
      }
    ]
  },
  {
    path: '/activetySg',
    redirect: '/activetySg/giftCardSg',
    name: 'activetySg',
    meta: {title: i18n.global.t('title.activety')},
    children: [
      {
        path: 'giftCardSg',
        name: 'activetySg',
        meta: {title: i18n.global.t('title.giftCard')},
        component: () => import(/* webpackChunkName: "redeemGiftCard" */ '@/views/activity-sg/redeemGiftCard'),
      },
      {
        path: 'giftCardSgEmail',
        name: 'activetySgEmail',
        meta: {title: i18n.global.t('title.giftCard')},
        component: () => import(/* webpackChunkName: "redeemGiftCard" */ '@/views/activity-sg/redeemGiftCardEmail'),
      },
      {
        path: 'giftCardResult',
        name: 'giftCardResult',
        meta: {title: i18n.global.t('title.giftCardResult')},
        component: () => import(/* webpackChunkName: "giftCardResult" */ '@/views/activity/giftCard/result.vue'),
      },
      {
        path: 'giftHistory',
        name: 'giftHistory',
        meta: {title: i18n.global.t('title.giftHistory')},
        component: () => import(/* webpackChunkName: "giftHistory" */ '@/views/activity/giftCard/history.vue'),
      }
    ]
  },
  {
    path: '/agreementzh',
    name: 'agreementzh',
    component: () => import(/* webpackChunkName: "about" */ '../views/userAgreement/userAgreement-zh.vue')
  },
  {
    path: '/agreementen',
    name: 'agreementen',
    component: () => import(/* webpackChunkName: "about" */ '../views/userAgreement/userAgreement-en.vue')
  },
  {
    path: '/privacyzh',
    name: 'privacyzh',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacyAgreement/privacyAgreementzh.vue')
  },
  {
    path: '/privacyen',
    name: 'privacyen',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacyAgreement/privacyAgreementen.vue')
  },

  {
    path: '/agreementzhandroid',
    name: 'agreementzhandroid',
    component: () => import(/* webpackChunkName: "about" */ '../views/userAgreement/userAgreement-zh-android.vue')
  },
  {
    path: '/agreementenandroid',
    name: 'agreementenandroid',
    component: () => import(/* webpackChunkName: "about" */ '../views/userAgreement/userAgreement-en-android.vue')
  },
  {
    path: '/privacyzhandroid',
    name: 'privacyzhandroid',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacyAgreement/privacyAgreementzh-android.vue')
  },
  {
    path: '/privacyenandroid',
    name: 'privacyenandroid',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacyAgreement/privacyAgreementen-android.vue')
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/downLoad/index.vue')
  },
  {
    path: '/memberRule-zh',
    name: 'memberRuleZh',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/rule-zh.vue')
  },
  {
    path: '/memberRule-en',
    name: 'memberRuleEn',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/rule-us.vue')
  },
  {
    path: '/memberRule-sg',
    name: 'memberRuleSG',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/rule-sg.vue')
  },
  {
    path: '/memberRule-us',
    name: 'memberRuleUS',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/rule-us.vue')
  },
  {
    path: '/member-agreement-zh',
    name: 'memberAgreementZh',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/agreement-zh.vue')
  },
  {
    path: '/member-agreement-en',
    name: 'memberAgreementen',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/agreement-sg.vue')
  },
  {
    path: '/member-agreement-SG',
    name: 'memberAgreementSG',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/agreement-sg.vue')
  },
  {
    path: '/member-agreement-US',
    name: 'memberAgreementUS',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/member/agreement-us.vue')
  },
  {
    path: '/adyenPay',
    name: 'adyenPay',
    meta: {
      title: 'instaChef',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/adyenPay/index.vue')
  },
  {
    path: '/inviting-sg',
    name: 'invitingSg',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/inviting/inviting.sg.vue'),
    children: [
      {
        path: 'agreementen',
        name: 'inviteAgreementsgen',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/userAgreement/userAgreement-en.vue')
      },
      {
        path: 'privacyen',
        name: 'invitePrivacysgen',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/privacyAgreement/privacyAgreementen.vue')
      }
    ]
  },
  {
    path: '/inviting-us',
    name: 'invitingUs',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/inviting/inviting.us.vue'),
    children: [
      {
        path: 'agreementen',
        name: 'inviteAgreementen',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/userAgreement/userAgreement-en.vue')
      },
      {
        path: 'privacyen',
        name: 'invitePrivacyen',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/privacyAgreement/privacyAgreementen.vue')
      }
    ]
  },
  {
    path: '/invite-error',
    name: 'inviteError',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/inviting/invite.error.vue')
  },
  {
    path: '/googleDesc',
    name: 'googleDesc',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/googleDesc/index.vue')
  },
  {
    path: '/find-order-phone',
    name: 'findOrder',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/phone.vue')
  },
  {
    path: '/push-order',
    name: 'pushOrder',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/Push.vue')
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    redirect: '/order/order-list',
    component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/order/order.vue'),
    children: [
      {
        path: 'order-list',
        name: 'OrderList',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/order/orderList.vue')
      },
      {
        path: 'order-detail',
        name: 'OrderDetail',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/order/orderDetail.vue')
      }
    ]
  },
  {
    path: '/find-order-desc',
    name: 'FindOrderDesc',
    meta: {
      title: 'instaChef',
    },
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/order/learn.vue')
  },
  {
    path: '/find-order-menu',
    name: 'VerifyOrder',
    meta: {
      title: 'instaChef',
    },
    // redirect: '/find-order-menu/find-order-step1',
    KeepAlive: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/VerifyOrder.vue'),
    children: [
      {
        path: 'find-order-step1',
        name: 'VeryOrderStep1',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/veryOrder/step1.vue')
      },
      {
        path: 'find-order-step2',
        name: 'VeryOrderStep2',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/veryOrder/step2.vue')
      },
      {
        path: 'find-order-step3',
        name: 'VeryOrderStep3',
        meta: {
          title: 'instaChef',
        },
        KeepAlive: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/findOrder/veryOrder/step3.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  console.log(to)
  window.document.title = to.meta.title || 'instaChef'
  next()
})

export default router
