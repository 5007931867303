import storage from 'store'
import {
    // i18n
    APP_LANGUAGE
} from '@/store/mutation-types'
// import { loadLanguageAsync } from '@/locales'

const app = {
    state: {
        lang: storage.get(APP_LANGUAGE)
    },
    getters: {
      getLang: state => state.lang
    },
    mutations: {
        [APP_LANGUAGE]: (state, lang, antd = {}) => {
            state.lang = lang
            state._antLocale = antd
            storage.set(APP_LANGUAGE, lang)
        }
    },
    actions: {
        setLang ({ commit }, lang) {
          return new Promise((resolve) => {
            commit(APP_LANGUAGE, lang)
            // locale.value = lang
            resolve()
            // loadLanguageAsync(lang).then(() => {
            //   resolve()
            // }).catch((e) => {
            //   reject(e)
            // })
          })
        }
    }
}
export default app