export default {
    'invite.fsyzm': '发送验证码',
    'invite.btntext1': '获取',
    'invite.btntext2': '优惠券',
    'invite.wty': '我已阅读并同意',
    'invite.yhxy': '《用户协议》',
    'invite.yhxy2': '《隐私政策》',
    'invite.and': '和',
    'invite.zh': '祝贺！你获得了',
    'invite.yhq': '代金券',
    'invite.xztip': '下载InstaChef APP，享受特别折扣和美食！',
    'invite.xz': '立即下载',
    'invite.verytip': '通过注册，您同意接收InstaChef的促销信息。回复STOP退出。消息频率不同，可能会收取数据费用。查看条款和隐私政策。InstaChef和Aikit Pte Ltd.保留所有权利。',
    'invite.verytip1': 'By signing up, you agree to receive promotional messages from InstaChef. Reply STOP to opt-out. Message frequency varies, and data rates may apply. View',
    'invite.verytip2': 'Terms and Privacy Policy',
    'invite.verytip3': '. All rights reserved by InstaChef and Aikit Pte Ltd.',
    'invite.verytipus1': 'By signing up, you agree to receive promotional messages from InstaChef. Reply STOP to opt-out. Message frequency varies, and data rates may apply. View Terms and Privacy Policy. All rights reserved by InstaChef and Aikit Pte Ltd.',
    'invite.verytipus2': 'Terms and Privacy Policy',
    'invite.verytipus3': '. All rights reserved by InstaChef and Aikit Pte Ltd.',
    // 'invite.gxyhxy': '您阅读并同意',
}