export default {
    'giftCard.zh': 'Phone number',
    'giftCard.sryzm': 'Verification code',
    'giftCard.tit': 'Enter your phone number to claim your gift card',
    'giftCard.mflq': 'Ready for my gift card!',
    'giftCard.zhtip': 'Phone number',
    'giftCard.fsyam': 'Get Code',
    'giftCard.sjtip': 'Voucher Redemption Date:',
    'giftCard.jsq': 'The right to interpret the event belongs to ',
    'giftCard.ckwdlpk': 'View my gift card',
    'giftCard.zg': 'China',
    'giftCard.xjp': 'Singapore',
    'giftCard.mg': 'United States',
    'giftCard.qxyzsjh': 'Please verify your phone number first',
    'giftCard.sjcwts': 'Please enter the correct phone number',
    'giftCard.yamcwts': 'Please enter the correct phone number verification code',
    'giftCard.wdlpk': 'My gift card',
    'giftCard.ye': 'Balance',
    'giftCard.sysm': 'Instructions for use',
    'giftCard.lqcg': 'Claimed successfully!',
    'giftCard.mz': 'Denomination',
    'giftCard.mztip': ' gift card has been added to your account',
    'giftCard.y': ' yuan',
    'giftCard.yxq': 'Expiration date',
    'giftCard.dz': 'Address',
    'giftCard.dhm': 'Code',
    'giftCard.verytip': 'By signing up for phone number and text, you agree to Instachef’s Terms of Service and Privacy Policy. By signing up, you agree to receive automated promotional and personalized marketing messages. Consent is not a condition of any purchase. Reply STOP to cancel. Msg frequency varies and data rates may apply. View Terms and Privacy.©2023 AI Cook, LLC, all rights reserved.'
}
