export default {
    'stockLevel.hd': '货道',
    'stockLevel.spmc': '商品名称',
    'stockLevel.tps': '调平数',
    'stockLevel.hgks': '货柜可售',
    'stockLevel.qrtp': '确认调平',
    'stockLevel.tptip': '请调整货柜可售商品数量',
    'stockLevel.sbmc': '设备名称',
    'stockLevel.tpsj': '调平时间',
    'stockLevel.gzmc': '柜子名称',
    'stockLevel.xzkctpyy': '选择库存调平原因',
    'stockLevel.tpyytip': '注：涉及成本计算，请按照实际情况准确选择调平原因',
    'stockLevel.qd': '确定',
    // 1:上错货 2:调整商品货道 3:盘点差异 4调拨出入库 5出库后回库 6:过期 7:商品破损 8:新品下架 9:其他
    'stockLevel.sch': '上错货',
    'stockLevel.tzsp': '调整商品货道',
    'stockLevel.pdcy': '盘点差异',
    'stockLevel.dbck': '调拨出入库',
    'stockLevel.ckh': '出库后回库',
    'stockLevel.gq': '过期',
    'stockLevel.spps': '商品破损',
    'stockLevel.xpxj': '新品下架',
    'stockLevel.qt': '其他',
    'stockLevel.qxzyy': '请选择原因',
    'stockLevel.search.tip': '请输入商品名称',
    'stockLevel.sblx': '设备类型',
    'stockLevel.qtyy': '其他原因'
}
