export default {
    'giftCardSg.dhm': '兑换码',
    'giftCardSg.yxq': '有效期',
    'giftCardSg.xztip': '下载Instachef APP查看',
    'giftCardSg.xztip1': '下载Instachef APP使用',
    'giftCardSg.xz': '下载',
    'giftCardSg.phonetip': '请输入手机号可以免费得到一张',
    'giftCardSg.lpk': '礼品卡',
    'giftCardSg.lqcgtext': '领取成功',
    'giftCardSg.to': '-',
    'giftCardSg.mflq': '免费领取',
    'giftCardSg.verytip': '',
    'giftCardSg.emailtip': '请输入邮箱可以免费得到一张',
    'giftCard.yxtip': '请输入邮箱',
    'giftCard.yxfscg': '发送成功',
    'giftCardSg.yxcwts': '邮箱错误，请检查后重试'
}