import pointList from './zh-CN/pointList'
import list from './zh-CN/list'
import pointDetail from './zh-CN/pointDetail'
import stockLevel from './zh-CN/stockLevel'
import search from './zh-CN/search'
import onSales from './zh-CN/onSales'
import bagDetail from './zh-CN/bagDetail'
import onShelves from './zh-CN/onShelves'
import me from './zh-CN/me'
import task from './zh-CN/task'
import enumerate from './zh-CN/enumerate'
import modal from './zh-CN/modal'
import onShelves2 from './zh-CN/onShelves2'
import stock from './zh-CN/stock'
import replenish from './zh-CN/replenish'
import giftCard from './zh-CN/giftCard'
import title from './zh-CN/title'
import giftCardSg from './zh-CN/giftCardSg'
import invite from './zh-CN/invite'

export default {
    login: '登陆',
    momentName: 'zh-cn',
    ...pointList,
    ...list,
    ...pointDetail,
    ...stockLevel,
    ...search,
    ...onSales,
    ...bagDetail,
    ...onShelves,
    ...me,
    ...task,
    ...enumerate,
    ...modal,
    ...onShelves2,
    ...stock,
    ...replenish,
    ...giftCard,
    ...title,
    ...giftCardSg,
    ...invite
}