// import storage from 'store'
import {
    // i18n
    POINT_GROUPE_SALES_INFO
} from '@/store/mutation-types'
// import { loadLanguageAsync } from '@/locales'

const app = {
    state: {
        pointGroupSalesInfo: {}
    },
    getters: {
        pointGroupSalesInfo:(state) => {
            return state.pointGroupSalesInfo
        }
    },
    mutations: {
        [POINT_GROUPE_SALES_INFO]: (state, data) => {
            console.log(12333, data)
            state.pointGroupSalesInfo = data
        }
    },
    actions: {
        setPointGroupSalesInfo ({ commit }, info) {
            commit(POINT_GROUPE_SALES_INFO, info)
        }
    }
}
export default app