export default {
    'newShelves2.noroad': 'No goods on the shelf, no need to check the inventory',
    'newShelves2.kc': 'Inventory',
    'newShelves2.hy': 'There are still',
    'newShelves2.d': 'and so on',
    'newShelves2.dtip': 'items of excess inventory that have not been shelved',
    'newShelves2.ckxq': 'View details',
    'newShelves2.xg': 'Modify',
    'newShelves2.qr': 'Confirm',
    'newShelves2.hdh': 'Lane number',
    'newShelves2.spmc': 'Product name',
    'newShelves2.cltip': 'Excess data can be replenished and shelved in the vending machine later',
    'newShelves2.lx': 'Type',
    'newShelves2.sl': 'Quantity',
    'newShelves2.bky': 'Disable'
}