export default {
    'stockLevel.hd': 'Position',
    'stockLevel.spmc': 'Product Description',
    'stockLevel.tps': 'Adjustment',
    'stockLevel.hgks': 'Actual Stock on hand',
    'stockLevel.qrtp': 'Confirm',
    'stockLevel.tptip': 'Please adjust the quantity of goods available for sale in the container',
    'stockLevel.sbmc': 'Equipment name',
    'stockLevel.tpsj': 'Leveling time',
    'stockLevel.gzmc': 'Cabinet name',
    'stockLevel.xzkctpyy': 'Select inventory leveling reason',
    'stockLevel.tpyytip': 'Note: If cost calculation is involved, please accurately select the leveling reason according to the actual situation',
    'stockLevel.qd': 'determine',
    // 1:上错货 2:调整商品货道 3:盘点差异 4调拨出入库 5出库后回库 6:过期 7:商品破损 8:新品下架 9:其他
    'stockLevel.sch': 'Wrong shipment',
    'stockLevel.tzsp': 'Adjust commodity shipping lanes',
    'stockLevel.pdcy': 'Inventory Variance',
    'stockLevel.dbck': 'Transfer receipt/issue',
    'stockLevel.ckh': 'Return to warehouse after delivery',
    'stockLevel.qxzyy': 'Please select inventory leveling reason',
    'stockLevel.search.tip': 'Product name',
    'stockLevel.sblx': 'Type',
    'stockLevel.qtyy': 'Other'
}